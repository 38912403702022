.transparent-hover {
  :hover button {
    background-color: transparent;
  }
}

.navButtons {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s 0.3s, opacity 0.3s linear;
}

.container {
  &:hover .navButtons {
    opacity: 1 !important;
    visibility: visible !important;
    transition: opacity 0.3s linear;
  }

  > div > div > div > div > div {
    position: relative;
  }
}
